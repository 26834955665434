<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head is-block">
      <div class="is-size-5" v-html="$nl2br(title)"></div>
      <div
        v-if="description"
        class="content mt-4 notification is-dark is-fullwidth"
        v-html="$nl2br(description)"
      ></div>
    </header>
    <section class="modal-card-body">
      <div
        v-for="option in sortedOptions"
        :key="option.name"
        :id="option.name"
        class="superOption"
      >
        <b-message
          class="mb-5"
          :type="
            activeOption == option.name || activeOption == option.value
              ? 'is-success'
              : 'is-text'
          "
        >
          <strong v-if="translationPath">{{
            $t(`${translationPath}.${option.name}`)
          }}</strong>
          <strong v-else>{{ option.name }}</strong>
          <b-button
            v-if="activeOption != option.name && activeOption != option.value"
            @click="selectOption(option.value ?? option.name)"
            class="is-pulled-right"
          >
            Velg</b-button
          >

          <b-button
            v-if="
              option.description2 &&
                option.name !== activeOption &&
                option.value !== activeOption
            "
            class="is-pulled-right pt-3"
            @click="toggleDescription(option.name)"
          >
            <b-tooltip
              type="is-black"
              :label="isOpen(option.name) ? 'Les mindre' : 'Les mer'"
            >
              <b-icon
                :icon="isOpen(option.name) ? 'chevron-up' : 'chevron-down'"
              ></b-icon
            ></b-tooltip>
          </b-button>
          <div class="content" v-html="$nl2br(option.description)"></div>

          <b-collapse
            animation="slide"
            duration="1500"
            :open="
              isOpen(option.name) ||
                option.name === activeOption ||
                option.value === activeOption
            "
          >
            <div
              class="content extended"
              v-html="$nl2br(option.description2)"
            ></div>
          </b-collapse>
        </b-message>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-right">
      <b-button type="is-primary" label="Lukk" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
import enums from "../mixins/enums";

export default {
  name: "ExplanationModal",
  data() {
    return {
      title: enums[this.type].title,
      description: enums[this.type].description ?? "",
      translationPath: enums[this.type].translationPath,
      options: {},
      expandedOptions: []
    };
  },

  props: {
    activeOption: {
      default: null
    },
    type: {
      default: {}
    }
  },

  computed: {
    sortedOptions() {
      const repoTypes = enums[this.type].options;
      const translationPath = enums[this.type].translationPath;

      // if options.value is not set, copy the name to value
      repoTypes.forEach(option => {
        if (!option.value && option.value !== "") {
          option.value = option.name;
        }
      });

      return repoTypes.sort((a, b) => {
        let aTranslation = a.name;
        let bTranslation = b.name;

        if (translationPath) {
          aTranslation = this.$t(`${translationPath}.${a.name}`);
          bTranslation = this.$t(`${translationPath}.${b.name}`);
        }

        return aTranslation.localeCompare(bTranslation);
      });
    }
  },

  methods: {
    selectOption(optionName) {
      this.$emit("select-option", optionName);
      this.$emit("close");
    },
    toggleDescription(optionName) {
      this.$set(this.expandedOptions, optionName, !this.isOpen(optionName));
    },
    isOpen(optionName) {
      return this.expandedOptions[optionName] ?? false;
    }
  },

  mounted() {
    // scroll to id matching the active option and pulsate it
    if (this.activeOption) {
      const el = document.getElementById(this.activeOption);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        el.classList.add("pulse");
        setTimeout(() => {
          el.classList.remove("pulse");
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss"></style>
