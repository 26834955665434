<template>
  <div class="is-user-avatar">
    <img
      v-if="newUserAvatar"
      :src="newUserAvatar"
      style="max-height: 2.8em; height: 3.2em;"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserAvatar",
  props: {
    avatar: {
      type: String,
      default: null
    }
  },
  computed: {
    newUserAvatar() {
      if (this.avatar) {
        return this.avatar;
      }

      if (this.userAvatar) {
        return this.userAvatar;
      }

      let seeds = [
        "Leo",
        "Harley",
        "Sophie",
        "Smokey",
        "Pumpkin",
        "Bubba",
        "Patches",
        "George",
        "Toby",
        "Cleo",
        "Fluffy",
        "Boo",
        "Bandit",
        "Buddy",
        "Oreo",
        "Jasmine",
        "Lilly",
        "Lucy"
      ];

      // set seed to be a random value from the array
      let seed = "Leo";
      let flip = false;
      let type = "notionists-neutral";
      let scale = 100;
      const rnd = seeds[Math.floor(Math.random() * seeds.length)];

      switch (this.user.email) {
        case "steffen@stadssalg.no":
        case "robin@stadssalg.no":
        case "john.robin@santanderconsumer.no":
          seed = rnd;
          break;

        case "sfenne@stadssalg.no":
          //seed = rnd;
          seed = "Cuddles";
          flip = true;
          type = "adventurer";
          scale = 120;
          break;

        case "erik@stadssalg.no":
          seed = "Mittens";
          flip = true;
          type = "adventurer";
          scale = 120;
          break;

        case "sander@stadssalg.no":
          seed = "Angel";
          flip = true;
          type = "adventurer";
          scale = 120;
          break;

        case "helene@stadssalg.no":
          // seed = "Jasmine";
          seed = "Pepper";
          flip = true;
          scale = 180;
          type = "croodles";
          break;

        case "nora@stadssalg.no":
          seed = "Bandit";
          flip = true;
          scale = 160;
          type = "croodles";
          break;

        case "gerd@stadssalg.no":
          seed = "Lilly";
          break;

        case "daniel@stadssalg.no":
          seed = "Pumpkin";
          break;

        default:
          seed = null;
          break;
      }

      if (seed) {
        return `https://api.dicebear.com/7.x/${type}/svg?seed=${seed}&flip=${flip}&scale=${scale}`;
      }
    },
    ...mapState(["userAvatar", "user"])
  }
};
</script>
