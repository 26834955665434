const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setHasUpdates(state, payload) {
    state.hasUpdates = payload;
  },
  setHasChatUpdates(state, payload) {
    state.hasChatUpdates = payload;
  },
  setUserDocument(state, payload) {
    if (payload != null) {
      payload.subscriptions = {};
    }
    state.userDocument = payload;
  },
  setUserModules(state, payload) {
    state.userModules = payload;
  },
  setUserAgents(state, payload) {
    state.userAgents = payload;
  },
  setChatEnabled(state, payload) {
    state.isChatEnabled = payload;
  },
  setUserLocations(state, payload) {
    state.userLocations = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setIsAllowedChat(state, payload) {
    state.isAllowedChat = payload;
  },
  setIsUserSuper(state, payload) {
    state.isUserSuper = payload;
  },
  setIsUserAdmin(state, payload) {
    state.isUserAdmin = payload;
  },
  setIsUserAgent(state, payload) {
    state.isUserAgent = payload;
  },
  setIsUserReadyForSaleDate(state, payload) {
    state.isUserReadyForSaleDate = payload;
  },
  setIsUserReadyForSaleInternal(state, payload) {
    state.isUserReadyForSaleInternal = payload;
  },
  setLoginStatus(state, payload) {
    state.loginStatus = payload;
  },
  setUserFirestoreListener(state, payload) {
    state.userFirestoreListener = payload;
  }
};

export default mutations;
