<script>
import useRegisterSW from "@/mixins/useRegisterSW";

const intervalMS = 5 * 60 * 1000; // 5 minutt oppdateringsintervall

export default {
  name: "ReloadPrompt",
  mixins: [useRegisterSW],
  methods: {
    handleSWManualUpdates(r) {
      r &&
        setInterval(() => {
          r.update();
        }, intervalMS);
    }
  }
};
</script>

<template>
  <div v-if="needRefresh" class="overlay-container">
    <div class="overlay-content">
      <p class="subtitle">Nettsiden har fått en ny oppdatering.</p>
      <p>Vennligst trykk på knappen under for å laste inn endringene.</p>

      <p class="mt-2">
        <strong>OBS!</strong> Siden vil oppdatere seg (lagre evt. arbeid)
      </p>

      <div class="mt-5 has-text-right">
        <b-button
          icon-left="refresh"
          type="is-primary"
          @click="updateServiceWorker()"
        >
          Oppdater
        </b-button>
      </div>
    </div>
  </div>
</template>

<style>
.overlay-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
}

.overlay-content {
  background-color: #fff;
  padding: 22px;
  border-radius: 4px;
  box-shadow: rgba(2, 2, 3, 0.35) 0px 10px 30px 0px;
}
</style>
