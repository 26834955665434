<template>
  <aside
    :class="
      `aside is-placed-right ${isAsideRightVisible ? 'notifications' : ''} ${
        isChatVisible ? 'chat-widget' : ''
      }`
    "
  >
    <div ref="container" class="aside-container" @mouseenter="psUpdate">
      <aside-updates v-if="isAsideRightVisible" @data-updated="psInit" />
      <Chatbox
        @isChatVisible="isChatVisible"
        :module="chat.module"
        :messages="chat.messages"
        :itemId="chat.id"
        v-if="isChatVisible"
        @data-updated="psInit"
      />
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import AsideUpdates from "@/components/AsideUpdates";
import Chatbox from "@/components/ChatBoxNew";

export default {
  name: "AsideRight",
  components: { AsideUpdates, Chatbox },
  computed: {
    ...mapState(["isAsideRightVisible", "isChatVisible"])
  },
  data() {
    return {
      chat: {
        module: "",
        messages: {},
        id: ""
      }
    };
  },
  methods: {
    psInit() {
      this.ps = new PerfectScrollbar(this.$refs.container, {
        wheelSpeed: 0.2,
        wheelPropagation: false
      });
    },
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    },
    setModule(routeName) {
      switch (routeName) {
        case "item.edit.preparation":
          this.chat.module = "preparation";
          break;
        case "item.edit.info":
          this.chat.module = "info";
          break;
        case "item.edit.valuation":
          this.chat.module = "valuation";
          break;
        case "item.edit.sale":
          this.chat.module = "sale";
          break;
        case "item.edit.economy":
          this.chat.module = "economy";
          break;
        case "item.edit.museum":
          this.chat.module = "museum";
          break;

        default:
          this.chat.module = "";
          break;
      }
    }
  },
  watch: {
    $route: function(newRoute, oldRoute) {
      if (oldRoute?.params?.id && newRoute?.params?.id) {
        if (oldRoute.params.id !== newRoute.params.id) {
          // only close aside if chat is open
          if (this.isChatVisible) {
            this.$store.commit("chatToggle", false);
            this.$store.commit("setChatEnabled", false);
          }
        }
      }

      this.setModule(newRoute.name);
    },

    isAsideRightVisible: function(visible) {
      if (visible) {
        const handleClick = event => {
          this.$store.commit("chatToggle", false);
          this.$store.commit("asideRightToggle");
        };

        document
          .querySelector(".page-wrapper div")
          .addEventListener("click", handleClick);

        this.handleClick = handleClick;
      } else {
        if (this.handleClick) {
          document
            .querySelector(".page-wrapper div")
            .removeEventListener("click", this.handleClick);
        }
      }
    },

    isChatVisible: function(visible) {
      if (visible) {
        this.chat.id = this.$route.params.id;
        this.setModule(this.$route.name);

        const handleClick = event => {
          this.$store.commit("asideRightToggle", false);
          this.$store.commit("chatToggle");
        };

        document
          .querySelector(".object-view")
          .addEventListener("click", handleClick);

        this.handleClick = handleClick;
      } else {
        if (this.handleClick) {
          document
            .querySelector(".object-view")
            .removeEventListener("click", this.handleClick);
        }
      }
    }
  }
};
</script>
