<template>
  <div class="modal-card" style="width: 960px;">
    <header class="modal-card-head">
      <p class="modal-card-title title">
        Dine favoritter
      </p>
    </header>
    <section class="modal-card-body">
      <b-table
        class="table"
        narrowed
        :loading="isLoading"
        paginated
        :total="favorites.length"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        :data="favorites"
      >
        <section slot="bottom-left">
          Viser
          <b-select v-model="perPage" class="is-inline-flex">
            <option value="5">5 per side</option>
            <option value="10">10 per side</option>
            <option value="25" selected>25 per side</option>
            <option value="50">50 per side</option>
            <option value="100">100 per side</option>
          </b-select>

          av totalt {{ favorites.length }} objekter
        </section>

        <b-table-column
          field="id"
          label="ID"
          cell-class="is-vcentered"
          sortable
          v-slot="props"
        >
          <router-link
            :to="{
              name: 'item.edit',
              params: { id: props.row.itemId }
            }"
          >
            {{ props.row.item.id }}
          </router-link>
        </b-table-column>

        <b-table-column
          field="title"
          label="Tittel"
          cell-class="is-vcentered"
          sortable
          v-slot="props"
        >
          {{ props.row.item.title }}
        </b-table-column>

        <b-table-column
          field="message"
          label="Melding"
          cell-class="is-vcentered"
          sortable
          v-slot="props"
        >
          {{ props.row.message }}
        </b-table-column>

        <b-table-column width="80" label="Slette?" centered v-slot="props">
          <div class="field-body is-justify-content-center">
            <p class="control">
              <b-button
                type="is-danger"
                label="Slett"
                @click="() => deleteFavorite(props.row.id)"
              />
            </p>
          </div>
        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large" />
              </p>
              <p>Henter data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large" />
              </p>
              <p>Fant ingen data!</p>
            </template>
          </div>
        </section>
      </b-table>
    </section>
    <footer class="modal-card-foot is-justify-content-right">
      <b-button type="is-primary" label="Lukk" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";

const isLoading = ref(false);
const perPage = ref(25);
const favorites = ref([]);

const app = getCurrentInstance().proxy;

const getData = () => {
  app.$axios
    .get(`${window.apiURL}/admin/favorites`)
    .then(response => {
      if (response.data && response.data) {
        favorites.value = response.data;
      } else {
        app.$buefy.toast.open({
          message: `Ukjent feil`,
          type: "is-danger",
          queue: false
        });
      }
    })
    .catch(e => {
      app.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: "is-danger",
        queue: false
      });
    });
};

const deleteFavorite = id => {
  isLoading.value = true;
  app.$axios
    .delete(`${window.apiURL}/admin/favorites/${id}`)
    .then(response => {
      // remove reminder from list
      favorites.value = favorites.value.filter(item => item.id !== id);

      app.$buefy.toast.open({
        message: response.data.message,
        type: "is-success"
      });
    })
    .catch(e => {
      app.$buefy.toast.open({
        message: e.response?.data?.message
          ? e.response.data.message
          : e.message,
        type: "is-danger",
        queue: false
      });
    })
    .finally(() => {
      isLoading.value = false;
    });
};

getData();
</script>

<style lang="scss"></style>
