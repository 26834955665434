export default {
    name: 'useRegisterSW',
    data() {
      return {
        updateSW: undefined,
        offlineReady: false,
        needRefresh: false
      }
    },
    async mounted() {
      try {
        const { registerSW } = await import('virtual:pwa-register')
        const vm = this
        this.updateSW = registerSW({
          immediate: true,
          onOfflineReady() {
            vm.offlineReady = true;
            vm.onOfflineReadyFn();
          },
          onNeedRefresh() {
            console.log("need refresh");
            vm.needRefresh = true;
            vm.onNeedRefreshFn();
          },
          onRegistered(swRegistration) {
            console.log("is registered");
            swRegistration && vm.handleSWManualUpdates(swRegistration);
          },
          onRegisterError(e) {
            console.log("big error");
            vm.handleSWRegisterError(e);
          }
        })
        console.log('PWA is enabled.')
      }
      catch {
        console.log('PWA is disabled.')
      }
  
    },
    methods: {
      async closePromptUpdateSW() {
        this.offlineReady = false
        this.needRefresh = false
      },
      onOfflineReadyFn() {
      },
      onNeedRefreshFn() {
      },
      updateServiceWorker() {
        this.updateSW && this.updateSW(true)
      },
      handleSWManualUpdates(swRegistration) {},
      handleSWRegisterError(error) {}
    }
}