<template>
  <div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <p class="title pl-3 pt-3">Meldinger</p>
    <div
      :class="
        `chat-message
          ${
            message.internal ? 'internal notification is-warning is-light' : ''
          }`
      "
      v-for="message in currentMessagesFiltered"
      :key="message.id"
    >
      <p class="is-size-7 userDetails">
        {{ message.user?.displayName ? message.user?.displayName : "ukjent" }}
        skrev
        <strong v-if="message.internal">internt</strong>

        <span>
          i <strong>{{ $t(`items["${message.module}"]`) }} </strong>
        </span>

        for
        <b-tooltip :label="getFormattedDateTime(message.createdAt)">
          {{ getRelativeTime(message.createdAt) }} siden, kl.
          {{ getFormattedDateTime(message.createdAt, "HH:mm") }}
        </b-tooltip>
      </p>
      <p class="is-right is-pulled-right is-size-7"></p>
      <div class="buttons is-pulled-right">
        <b-button
          v-if="message.userId != currentUser.uid"
          type="is-info is-light"
          size="is-small"
          class="p-2"
          icon-left="reply"
          @click="replyMessage(message, message.id)"
          >Svar</b-button
        >
        <b-button
          v-else
          type="is-danger is-light"
          size="is-small"
          class="p-2"
          icon-left="delete"
          @click="deleteMessage(message.id, message.module)"
          >Slett</b-button
        >
      </div>
      <div
        class="comment"
        :inner-html.prop="message.message | usernames(message.parentId)"
      ></div>

      <ChatBoxForm
        :isLoading="isLoading"
        :message="replyText"
        :module="message.module"
        @sendMessage="sendMessage"
        v-if="replyForm === message.id"
        btnTitle="Svar"
        :id="message.id"
      ></ChatBoxForm>
    </div>

    <div
      class="notification is-info is-light"
      v-if="!currentMessagesFiltered.length && !isLoading"
    >
      Ingen har skrevet noen melding enda.
    </div>

    <ChatBoxForm
      :isLoading="isLoading"
      :module="module"
      @sendMessage="sendMessage"
      btnTitle="Send"
    ></ChatBoxForm>
  </div>
</template>

<script>
import helpers from "@/helpers";
import { mapGetters } from "vuex";
import AsideUpdatesItem from "@/components/AsideUpdatesItem";

import ChatBoxForm from "./ChatBoxFormNew.vue";

export default {
  name: "Chatbox",

  mixins: [helpers],
  components: { AsideUpdatesItem, ChatBoxForm },
  props: {
    itemId: {
      default: 0
    },
    messages: {
      default: function() {
        return [];
      }
    },
    module: {
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      currentMessages: [],
      newMessages: [],
      currentUser: null,
      updateKey: 0,
      replyInModule: "",
      replyForm: null,
      replyText: "",
      message: ""
    };
  },
  computed: {
    ...mapGetters(["getUser", "isAllowedChat"]),
    currentMessagesFiltered() {
      const messages = this.currentMessages;

      if (messages.length) {
        //collecting all parents (whose parent is 0)
        let parents = messages.filter(x => x.parent == undefined);

        //collecting all chlidren (whose parent is not 0)
        let children = messages.filter(x => x.parent);

        let x;
        //iterating through children and pushing it to **parents** json just below the parent
        for (x of children) {
          let index = parents.findIndex(obj => obj.messageId === x.parent);

          parents.splice(index + 1, 0, x);
        }

        return parents;
      } else {
        return [];
      }
    }
  },
  mounted() {},
  async created() {
    await this.loadMessages();
    this.currentUser = this.getUser;
  },

  methods: {
    loadMessages() {
      this.isLoading = true;
      if (this.itemId && this.isAllowedChat) {
        // flyttes til ChatBoxNew.vue
        this.$axios
          .get(`${window.apiURL}/admin/items/${this.itemId}/chat`)
          .then(data => {
            this.newMessages = data.data;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$store.commit("setChatEnabled", false);
          })
          .finally(() => {
            this.$emit("data-updated");
            // clear new messages
            const chatUpdates = JSON.parse(
              localStorage.getItem("hasChatUpdates") ?? "{}"
            );
            chatUpdates[this.itemId] = this.newMessages.length;
            localStorage.setItem("hasChatUpdates", JSON.stringify(chatUpdates));
            this.isLoading = false;
          });
      }
    },
    pointUp(messageId) {
      this.$buefy.dialog.alert({
        title: "Ikke implementert enda",
        message: "Promotere melding " + messageId,
        type: "is-danger"
      });
    },
    pointDown(messageId) {
      this.$buefy.dialog.alert({
        title: "Ikke implementert enda",
        message: "Demotere melding " + messageId,
        type: "is-danger"
      });
    },
    deleteMessage(messageId, module) {
      if (module === "general") {
        module = "";
      }
      // check if this message has kids. if so, no deletion allowed
      const hasChildren = this.currentMessages.filter(
        msg => msg.parent == messageId
      );

      if (hasChildren.length > 0) {
        this.$buefy.dialog.alert({
          title: "Denne meldingen har svar",
          message:
            "Du får ikke slette denne meldingen på grunn av at den har ett eller flere svar under seg",
          type: "is-danger"
        });
      } else {
        this.$buefy.dialog.confirm({
          title: `Sletting av egen melding`,
          message:
            "Er du sikker på at du ønsker å slette denne meldingen? Dette kan ikke reverseres.",
          confirmText: "Ja",
          cancelText: "Avbryt",
          type: "is-primary",
          hasIcon: true,
          onConfirm: () => {
            this.$axios
              .post(`${window.apiURL}/admin/items/${this.itemId}/chat/delete`, {
                id: messageId
              })
              .then(() => {
                this.newMessages = this.newMessages.filter(msg => {
                  return msg.id !== messageId;
                });
              })
              .catch(e => {
                this.$buefy.toast.open({
                  message: `Error: ${e.message}`,
                  type: "is-danger",
                  queue: false
                });
              })
              .finally(() => {
                this.$emit("data-updated");
                this.isLoading = false;
              });
          }
        });
      }
    },
    replyMessage(message, messageId) {
      this.replyInModule = message.module;

      if (this.replyForm == messageId) {
        this.replyForm = null;
        this.replyText = "";
      } else {
        this.replyForm = messageId;
        this.replyText = message.user?.displayName
          ? `@${message.user.displayName} `
          : "";
      }
    },
    sendMessage(message, messageId, module, internal) {
      if (message) {
        this.isLoading = true;

        this.$axios
          .post(`${window.apiURL}/admin/items/${this.itemId}/chat`, {
            internal: internal,
            module:
              messageId && this.replyInModule ? this.replyInModule : module,
            message: message,
            id: messageId
          })
          .then(data => {
            this.replyForm = null;
            this.isLoading = false;

            // add newmessage in the beginning of the array
            this.newMessages.push(data.data.newMessage);
            this.newMessages = this.newMessages.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          })
          .catch(e => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: "is-danger",
              queue: false
            });
          })
          .finally(() => {
            this.$emit("data-updated");
            this.isLoading = false;
          });
      } else {
        this.$buefy.dialog.alert({
          title: "Feil",
          message: "Ingen melding skrevet inn.",
          type: "is-danger"
        });
      }
    }
  },
  watch: {
    newMessages: function(messages) {
      this.currentMessages = messages;
    }
  },
  filters: {
    usernames: function(string, messageId) {
      let usernames = string.match(/@([^ ]*)/g);
      let kake = string;
      if (usernames) {
        usernames.forEach(username => {
          kake = string
            .split(username)
            .join(
              `<a style="text-decoration: none;" href="#chat-${messageId}">${username}</a>`
            );
        });
      }

      return kake;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/chat";

.anchorHigher {
  position: relative;
  top: -200px;
}
// every other .chat-message should have a different background color
.chat-message {
  padding: 1.5em 1.5em 1.5em 1.5em;

  .userDetails {
    margin: -1.5em 0 1.5em 0;
  }

  &:nth-child(even):not(.internal) {
    background-color: #f5f5f5;
  }
}
</style>
