import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence
} from "firebase/auth";
import axios from "axios";

const actions = {
  signInAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, payload.email, payload.password)
            .then(response => {
              commit("setUser", response.user);
              commit("setLoginStatus", "loggedIn");
              resolve(response);
            })
            .catch(error => {
              commit("setError", error.message);
              reject(error);
            });
        })
        .catch(error => {
          commit("setError", error.message);
          reject(error);
        });
    });
  },

  signOutAction({ commit }) {
    signOut(getAuth())
      .then(() => {})
      .catch(error => {
        commit("setError", error.message);
      });
  },

  authAction({ commit }) {
    const loginTimeout = setTimeout(() => {
      commit("setLoginStatus", "loggedOut");
    }, 4000);

    commit("setLoginStatus", "loggingIn");

    onAuthStateChanged(getAuth(), async user => {
      clearInterval(loginTimeout);

      if (user) {
        const userClaims = (await user.getIdTokenResult()).claims;
        const userDocument = (
          await axios.get(`${window.apiURL}/profile`, {
            userId: user.uid
          })
        ).data;

        commit("setUser", user);
        commit("setUserDocument", userDocument);

        if (userClaims.locations) {
          commit("setUserLocations", userClaims.locations);

          // locationIds that dont have access to chat
          const restrictedLocationIds = [
            1,
            109, // theim
            2,
            55,
            92,
            112, // bergen
            17,
            118 // stavanger
          ];

          if (
            userClaims.locations.some(location =>
              restrictedLocationIds.includes(location.id)
            )
          ) {
            //commit("setIsAllowedChat", false); // default true
          }
        }

        if (userClaims.agents) commit("setUserAgents", userClaims.agents);
        if (userClaims.modules) commit("setUserModules", userClaims.modules);
        if (userClaims.readyForSaleDate)
          commit("setIsUserReadyForSaleDate", userClaims.readyForSaleDate);
        if (userClaims.readyForSaleInternal)
          commit(
            "setIsUserReadyForSaleInternal",
            userClaims.readyForSaleInternal
          );
        commit("setError", null);
        commit("setIsUserSuper", userClaims.role == "super");
        commit("setIsUserAdmin", userClaims.role == "admin");
        commit("setIsUserAgent", userClaims.role == "agent");
        commit("setLoginStatus", "loggedIn");
      } else {
        commit("setUser", null);
        commit("setUserDocument", null);
        commit("setError", null);
        commit("setIsUserSuper", false);
        commit("setIsUserAdmin", false);
        commit("setIsUserAgent", false);
        commit("setLoginStatus", "loggedOut");
      }
    });
  },

  clearData({ commit }) {
    commit("setUser", null);
    commit("setUserDocument", null);
    commit("setError", null);
  }
};

export default actions;
