<template>
	<div>
		<iframe
			ref="iframe"
			plausible-embed
			src="https://plausible.io/share/stadssalg.no?auth=XIaeJOBUus-lgzYtG_FAh&embed=true&theme=light&background=transparent"
			scrolling="no"
			frameborder="0"
			loading="lazy"
			style="width: 1px; min-width: 100%; height: 120em;"
    ></iframe>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				number: 0
			}
		},

		props: {
			id: {
				default: null
			},
		},
	}
</script>

<style lang="scss" scoped>

</style>