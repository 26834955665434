import Vue from "vue";
import Vuex from "vuex";
import each from "lodash/each";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

// get cookies
import VueCookies from "vue-cookies";

Vue.use(Vuex);

if (VueCookies.get("darkMode") === "true") {
  document.documentElement.classList.add("is-dark-mode-active");
}

export default new Vuex.Store({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    isAsideRightVisible: false,

    /* Chat */
    isChatEnabled: false,
    isChatVisible: false,

    /* Updates */
    hasUpdates: false,
    hasChatUpdates: false,

    /* Overlay */
    isOverlayVisible: false,

    /* Dark mode */
    isDarkModeActive: VueCookies.get("darkMode") ?? false,

    user: null,
    userDocument: null,
    userModules: [],
    userAgents: [],
    userLocations: [],
    error: null,
    isAllowedChat: true,
    isUserSuper: false,
    isUserAdmin: false,
    isUserAgent: false,
    isUserReadyForSaleDate: false,
    isUserReadyForSaleInternal: false,
    loginStatus: "init",
    userFirestoreListener: null
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    /* User */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name;
      }
      if (payload.email) {
        state.userEmail = payload.email;
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar;
      }
    },

    /* Full Page mode */
    fullPage(state, payload) {
      state.isNavBarVisible = !payload;
      state.isAsideVisible = !payload;
      state.isFooterBarVisible = !payload;

      each(["has-aside-left", "has-navbar-fixed-top"], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass);
        } else {
          document.documentElement.classList.add(htmlClass);
        }
      });
    },

    /* Aside Desktop */
    asideStateToggle(state, payload = null) {
      const htmlAsideClassName = "has-aside-expanded";

      let isExpand;

      if (payload !== null) {
        isExpand = payload;
      } else {
        isExpand = !state.isAsideExpanded;
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName);
      } else {
        document.documentElement.classList.remove(htmlAsideClassName);
      }

      state.isAsideExpanded = isExpand;
      state.hasUpdates = false;
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = "has-aside-mobile-expanded";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle(state, payload) {
      state.asideActiveForcedKey =
        payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null;
    },

    /* Aside Right */
    asideRightToggle(state, payload = null) {
      const htmlClassName = "has-aside-right";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideRightVisible;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
        state.hasUpdates = false;
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideRightVisible = isShow;
    },

    /* Chat toggle */
    chatToggle(state, payload = null) {
      const htmlClassName = "has-aside-right";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isChatVisible;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
        state.hasChatUpdates = false;
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isChatVisible = isShow;
    },

    /* Overlay */
    overlayToggle(state, payload = null) {
      if (payload === null) {
        payload = !state.isOverlayVisible;
      }

      state.isOverlayVisible = !!payload;
    },

    /* Dark Mode */
    darkModeToggle(state) {
      const htmlClassName = "is-dark-mode-active";

      state.isDarkModeActive = !state.isDarkModeActive;
      VueCookies.set("darkMode", state.isDarkModeActive);
      if (state.isDarkModeActive) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }
    }
  }
});
