<template>
  <div id="app">
    <nav-bar v-if="getLoginStatus == 'loggedIn'" />
    <aside-menu
      v-if="getLoginStatus == 'loggedIn'"
      :menu="menu()"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :class="{ 'has-secondary': !!menuSecondary }"
    />
    <aside-menu
      v-if="menuSecondary && getLoginStatus == 'loggedIn'"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryCloseClick"
    />
    <div class="page-wrapper">
      <router-view />
      <aside-right v-if="getLoginStatus == 'loggedIn'" />
      <footer-bar v-if="getLoginStatus == 'loggedIn'" />
    </div>
    <overlay />
    <reload-prompt />
  </div>
</template>

<script>
// @ is an alias to /src
// Tror dette endra seg med VITE, husker ikke xD

import { mapState, mapActions, mapGetters } from "vuex";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import Overlay from "@/components/Overlay";
import AsideRight from "@/components/AsideRight";
import ReloadPrompt from "@/components/ReloadPrompt";
import intersection from "lodash/intersection";

export default {
  name: "App",
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar,
    ReloadPrompt
  },

  data() {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null,
      menuItems: []
    };
  },
  computed: {
    menuBottom() {
      return [
        {
          action: "logout",
          icon: "logout",
          label: "Logg ut",
          state: "info"
        }
      ];
    },
    ...mapState(["isOverlayVisible"]),
    ...mapGetters([
      "getLoginStatus",
      "isUserAgent",
      "getUserModules",
      "getUserAgents"
    ])
  },
  created() {
    this.$store.commit("asideStateToggle", true);
  },
  mounted() {
    this.authAction();
  },
  methods: {
    ...mapActions(["authAction", "signOutAction"]),

    async dynamicMenuItems() {
      const params = {
        facet_by: "status",
        filter_by: JSON.stringify({})
      };

      await window.apiURL;

      this.$axios
        .get(`${window.apiURL}/admin/items`, {
          params: params
        })
        .then(({ data }) => {
          let statuses = data.facets.find(obj => obj.field_name === "status")
            .counts;

          this.menuItems = [];

          let position = {
            tobecreated: { position: 1 },
            onitsway: { position: 2 },
            preparation: { position: 3 },
            readyforsale: { position: 4 },
            active: { position: 5 },
            inactive: { position: 6 },
            inprocess: { position: 7 },
            fordelivery: { position: 8 },
            sold: { position: 9 }
          };

          if (this.isUserAgent) {
            const agents = this.getUserAgents.map(agent => agent.id);

            if (agents.length > 0) {
              const commonIds = intersection(agents, [215, 120, 327, 328, 329]);

              if (commonIds.length > 0) {
                position.recycled = { position: 10 };
              }
            }
          }

          statuses.map(item => {
            if (position[item.value]) {
              this.menuItems.push({
                to: "/items/?filter=status:" + item.value,
                label:
                  this.$t(`items.status["${item.value}"]`) +
                  " (" +
                  item.count +
                  ")",
                position: position[item.value].position
              });
            }
          });

          this.menuItems.sort((a, b) => a.position - b.position);

          return this.menuItems;
        });
    },

    menu() {
      const administrate = [];
      let bottom = [];
      let newItemBtn = [];
      const general = [];

      const other = [
        {
          to: "/reports",
          label: "Rapporter",
          icon: "file"
        }
      ];

      if (this.isUserAgent) {
        newItemBtn.push({
          to: "/items/agent/new",
          label: "Nytt objekt",
          icon: "plus",
          class: "has-text-weight-bold"
        });

        // we'll add reports later to the menu
        bottom = [other];
      }

      if (!this.isUserAgent) {
        newItemBtn.push({
          to: "/items/new",
          label: "Nytt objekt",
          icon: "plus",
          class: "has-text-weight-bold"
        });

        general.push({
          to: "/",
          icon: "desktop-mac",
          label: "Dashboard"
        });
        administrate.push(
          {
            label: "Brukere",
            icon: "account-multiple",
            to: "/users"
          },
          {
            label: "Oppdragsgivere",
            icon: "briefcase",
            to: "/agents"
          },
          {
            label: "Inkassobyrå",
            to: "/dcas",
            icon: "bank"
          },
          {
            label: "Firma",
            to: "/companies",
            icon: "domain"
          },
          {
            label: "Konkurser",
            to: "/bankruptcies",
            icon: "currency-usd-off"
          }
        );

        other.push(
          {
            to: "/items?filter=status%3Amuseum",
            label: "Museum",
            icon: "bank"
          },
          {
            to: "/logs",
            label: "Logg",
            icon: "history"
          },
          {
            to: "/locations",
            label: "Avdelinger",
            icon: "warehouse"
          },
          {
            to: "/settings",
            label: "Innstillinger",
            icon: "tools"
          }
        );

        bottom = ["Administrer", administrate, "Annet", other];
      }
      return [
        general,
        "Objekter",
        [
          {
            to: "/items",
            label: "Objekter",
            icon: "format-list-bulleted"
          },
          ...this.menuItems,

          ...newItemBtn
        ],

        ...bottom
      ];
    },

    menuClick(item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary;
        this.menuSecondaryLabel = item.menuSecondaryLabel
          ? item.menuSecondaryLabel
          : null;
        this.menuSecondaryIcon = item.menuSecondaryIcon
          ? item.menuSecondaryIcon
          : null;

        this.$store.commit("asideActiveForcedKeyToggle", item);
        this.$store.commit("overlayToggle", true);
      } else if (item.action) {
        if (item.action === "logout") {
          this.signOutAction();
          this.$router.push({
            path: "/login"
          });
        } else if (item.action === "dark-mode-toggle") {
          this.$store.commit("darkModeToggle");
        }
      }
    },
    menuSecondaryCloseClick() {
      this.$store.commit("overlayToggle", false);
    },
    menuSecondaryClose() {
      this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null;
      this.$store.commit("asideActiveForcedKeyToggle", null);
    }
  },
  watch: {
    isOverlayVisible(newValue) {
      if (!newValue) {
        this.menuSecondaryClose();
      }
    },
    getLoginStatus(newValue) {
      if (newValue == "loggedIn") {
        // dont add dynamic menu items if user only has access to museum
        if (
          this.getUserModules.length === 1 &&
          this.getUserModules[0] === "museum"
        ) {
          return;
        }

        this.dynamicMenuItems();
      }
    }
  }
};
</script>
