<template>
  <div :class="`${isLoading ? 'has-text-grey-light' : ''}`">
    <div v-html="sentence"></div>
    <div v-if="!sentence && !Object.keys(this.data.details).length">
      <p class="subtitle">Ingen data</p>
      Denne handlingen har ingen data å vise.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sentence: "Henter tekst...",
      isLoading: false
    };
  },
  props: {
    data: {
      default: {}
    }
  },
  mounted() {
    this.buildSentence();
  },
  methods: {
    async buildSentence() {
      this.isLoading = true;

      const name = this.data.user?.displayName
        ? this.data.user.displayName.split(" ")[0]
        : "*** ukjent ***";
      const module = this.data.module
        ? this.$t(`log.module.${this.data.module}`)
        : null;

      const user = this.data.details?.newData?.userId
        ? await this.getUser()
        : { displayName: "ukjent", email: "ukjent" };

      if (
        !this.data.details?.newData?.userId &&
        this.data.details?.newData?.email
      ) {
        user.email = this.data.details.newData.email;
      }

      const item = this.data.itemId ? await this.getItem() : null;

      const sentenceMapping = {
        create_object: async () => {
          return `${name} opprettet objekt ${item.title} (${item.id})`;
        },

        update_object: async () => {
          return `${name} oppdaterte objekt ${item.title} (${item.id})`;
        },
        create_user: async () => {
          return `${name} opprettet bruker ${user?.displayName} (${user?.email})`;
        },
        update_user: async () => {
          return `${name} oppdaterte bruker ${user?.displayName} (${user?.email})`;
        },

        add_file: async () => {
          if (this.data.details.newData.files) {
            return `${name} la til ${this.$tc(
              "filePluralization",
              this.data.details.newData.files.length
            )} for objekt ${item.title} (${item.id})`;
          } else {
            return `${name} la til filen ${this.data.details.newData.name} for objekt ${item.title} (${item.id})`;
          }
        },

        remove_file: async () => {
          return `${name} fjernet ${this.$tc(
            "filePluralization",
            this.data.details.newData.files.length
          )} for objekt ${item.title} (${item.id})`;
        },

        add_message: async () => {
          return `${name} la til en melding i ${this.$t(
            `log.module.${this.data.module}`
          )} for objekt ${item.title} (${item.id})`;
        },

        remove_message: async () => {
          return `${name} fjernet en melding fra ${this.$t(
            `log.module.${this.data.module}`
          )} til objekt ${item.title} (${item.id})`;
        },

        add_module: async () => {
          return `${name} slo på ${this.$t(
            `log.module.${this.data.module}`
          )} for objekt ${item.title} (${item.id})`;
        },

        remove_module: async () => {
          return `${name} slo av ${module} for objekt ${item.title} (${item.id})`;
        },

        set_date: async () => {
          // TODO: kanskje vi bruker newData.date for å vise hva som ble endret
          return `${name} satt dato ${module ? `i ${module}` : ""} for objekt ${
            item.title
          } (${item.id})`;
        },

        change_date: async () => {
          // TODO: kanskje vi bruker newData.date for å vise hva som ble endret
          return `${name} endret dato ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        clear_date: async () => {
          // TODO: kanskje vi bruker newData.date for å vise hva som ble endret
          return `${name} fjernet dato ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        set_field: async () => {
          return `${name} satt feltet ${
            Object.keys(this.data.details.newData)[0]
          } til ${
            this.data.details.newData[Object.keys(this.data.details.newData)[0]]
          } for objekt ${item.title} (${item.id})`;
        },

        change_field: async () => {
          return `${name} endret feltet ${
            Object.keys(this.data.details.newData)[0]
          } til ${
            this.data.details.newData[Object.keys(this.data.details.newData)[0]]
          } for objekt ${item.title} (${item.id})`;
        },

        clear_field: async () => {
          return `${name} fjernet verdien for feltet ${
            Object.keys(this.data.details.newData)[0]
          } for objekt ${item.title} (${item.id})`;
        },

        set_permissions_user: async () => {
          return `${name} endret tilgangen til bruker ${user?.displayName} (${user?.email})`;
        },

        add_note_user: async () => {
          return `${name} la til en notat for bruker ${user?.displayName} (${user?.email})`;
        },

        remove_note_user: async () => {
          return `${name} fjernet et notat for bruker ${user?.displayName} (${user?.email})`;
        },

        impersonate_user: async () => {
          return `${name} logget inn som ${user?.displayName} (${user?.email})`;
        },

        fetch_autosys: async () => {
          return `${name} hentet data fra Autosys ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        order_owner_info: async () => {
          return `${name} bestilte eierinformasjon ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        order_evaluation: async () => {
          return `${name} bestilte takst ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        promote_object: async () => {
          return `${name} promoterte objekt ${item.title} (${item.id})`;
        },

        generate_contract: async () => {
          return `${name} genererte kontrakt ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        send_invoice: async () => {
          return `${name} sendte faktura ${
            module ? `i ${module}` : ""
          } for objekt ${item.title} (${item.id})`;
        },

        set_auction_winner: async () => {
          return `${name} satt auksjonsvinneren til ${user?.displayName} (${user?.email}) for objekt ${item.title} (${item.id})`;
        },

        remove_auction_winner: async () => {
          return `${name} fjernet auksjonsvinneren for objekt ${item.title} (${item.id})`;
        }
      };

      const sentenceBuilder = sentenceMapping[this.data.action];

      if (sentenceBuilder) {
        const sentence = await sentenceBuilder();
        this.isLoading = false;

        this.sentence = sentence;
      } else {
        this.isLoading = false;
        this.sentence = "";
      }
    },

    async getItem() {
      const response = await this.$axios.get(
        `${window.apiURL}/admin/items/${this.data.itemId}`
      );
      const item = response.data;

      return item;
    },

    async getUser() {
      const response = await this.$axios.get(
        `${window.apiURL}/admin/users/${this.data.details.newData.userId}`
      );
      const user = response.data?.userDoc;

      return user;
    }
  }
};
</script>

<style lang="scss" scoped></style>
