const getters = {
  getUser(state) {
    return state.user;
  },
  getUserDocument(state) {
    return state.userDocument;
  },
  getUserModules(state) {
    return state.userModules;
  },
  getUserAgents(state) {
    return state.userAgents;
  },
  getUserLocations(state) {
    return state.userLocations;
  },
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getError(state) {
    return state.error;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  isAllowedChat(state) {
    return state.isAllowedChat;
  },
  isUserSuper(state) {
    return state.isUserSuper;
  },
  isUserAdmin(state) {
    return state.isUserAdmin;
  },
  isUserAgent(state) {
    return state.isUserAgent;
  },
  isUserPermissionReadyForSaleDate(state) {
    return state.isUserReadyForSaleDate;
  },
  isUserPermissionReadyForSaleInternal(state) {
    return state.isUserReadyForSaleInternal;
  }
};

export default getters;
