/* Styles */
import "@/scss/main.scss";

/* Core */
import Vue from "vue";
import i18n from "./i18n";
import Buefy from "buefy";

/* Router & Store */
import router from "./router";
import store from "./store";

/* Firebase */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

/* validation */
import "./vee-validate";

/* Dependencies */
import axios from "axios";
import * as Sentry from "@sentry/vue";

/* Vue. Main component */
import App from "./App.vue";

import LoadScript from "vue-plugin-load-script";
import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

/* Vue Cookies */
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("365d");

Vue.use(LoadScript);

/* custom nl2br prototype "filter" */
Vue.prototype.$nl2br = function(text) {
  return text ? text.replace(/\n/g, "<br>") : text;
};

/* Default title tag */
const defaultDocumentTitle = "Stadssalg Admin";

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit("asideMobileStateToggle", false);
  store.commit("overlayToggle", false);
  store.commit("asideActiveForcedKeyToggle", null);

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

var firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// initialize firebase
const firebase = initializeApp(firebaseConfig);

if (import.meta.env.NODE_ENV == "production") {
  Sentry.init({
    Vue,
    environment:
      location.hostname == "admin.stadssalg.no" ? "production" : "development",
    dsn:
      "https://6f4d765443184221b92bf3d34cb20579@o589996.ingest.sentry.io/4504208737632256",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["api.stadssalg.no", /^\//]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.2
  });
}

/* phone number input */
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput, {
  preferredCountries: ["no", "se", "dk"],
  autoDefaultCountry: true,
  autoFormat: true,
  inputOptions: {
    placeholder: "Skriv inn et telefonnummer",
    showDialCode: false
  },
  mode: "international",
  validCharactersOnly: true
});

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultToastDuration: 10000
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.documentElement.classList.remove("has-spinner-active");
  }
}).$mount("#app");

/* make firebase available */
Vue.prototype.$firebase = firebase;

// make axios available
Vue.prototype.$axios = axios;
Vue.prototype.$axios.url = import.meta.env.VITE_API_URL;

// all our item modules
Vue.prototype.$itemModules = [
  "preparation",
  "info",
  "valuation",
  "sale",
  "economy",
  "museum"
];

Vue.prototype.$accountingLocations = [
  108, // Oslo Skedsmo A.
  109, // TRONDHEIM A.
  112, // BERGEN A.
  113, // TROMSØ A.
  118, // STAVANGER A.
  122 // BODØ A.
];

// all our statuses
Vue.prototype.$itemStatuses = [
  "onitsway",
  "inactive",
  "outreach",
  "recycled",
  "still",
  "wrecked",
  "saleexcluded",
  "salestopped",
  "archived",
  "museum"
];

// all our log actions (copied from schema)
Vue.prototype.$logActions = [
  "create_object",
  "update_object",
  "delete_object",
  "create_user",
  "update_user",
  "delete_user",
  "add_file",
  "remove_file",
  "add_message",
  "remove_message",
  "add_module",
  "remove_module",
  "set_date",
  "change_date",
  "clear_date",
  "set_field",
  "change_field",
  "clear_field",
  "file_opened",
  "set_permissions_user",
  "add_note_user",
  "remove_note_user",
  "impersonate_user",
  "fetch_autosys",
  "order_owner_info",
  "order_evaluation",
  "send_valuation",
  "promote_object",
  "generate_contract",
  "send_invoice",
  "set_auction_winner",
  "remove_auction_winner",
  "add_reminder",
  "mark_reminder_as_read",
  "send_sms"
];

axios.interceptors.request.use(
  async config => {
    const auth = getAuth();
    const firebaseToken = auth.currentUser
      ? await auth.currentUser.getIdToken()
      : null;
    if (firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

window.apiURL = import.meta.env.VITE_API_URL;
window.mediaPath = import.meta.env.VITE_MEDIA_PATH;
