<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li v-for="(row, index) in titleStack" :key="index">
              <router-link :to="row.url" v-if="row.url">{{
                row.name
              }}</router-link>
              <span v-else>{{ row.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  }
};
</script>
