<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section
      class="section is-main-section dashboard"
      v-if="isUserAdmin || isUserSuper"
    >
      <b-tabs v-model="activeTab">
        <b-tab-item
          v-for="tab in tabs"
          :key="tab.id"
          :icon="tab.icon"
          :value="tab.id"
          :label="tab.label"
        >
          <component v-if="activeTab === tab.id" :is="tab.component" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script async src="https://plausible.io/js/embed.host.js"></script>
<script>
import TitleBar from "@/components/TitleBar";
import Items from "./home/Items.vue";
import Statistics from "./home/Statistics.vue";
import Payments from "./home/Payments.vue";
import { mapGetters } from "vuex";

export default {
  name: "home",
  data() {
    return {
      activeTab: "items",
      tabs: [
        {
          id: "items",
          label: "Objekter",
          icon: "car-brake-alert",
          component: Items
        },
        {
          id: "stats",
          label: "Statistikk",
          icon: "chart-bar",
          component: Statistics
        },
        {
          id: "payments",
          label: "Innbetalinger",
          icon: "bank-check",
          component: Payments
        }
      ]
    };
  },

  components: {
    TitleBar,
    Items,
    Statistics
  },

  computed: {
    titleStack() {
      return [{ name: "Dashboard", url: null }];
    },
    ...mapGetters(["isUserAgent", "isUserAdmin", "isUserSuper"])
  },
  mounted() {},
  methods: {},
  watch: {}
};
</script>
<style lang="scss">
.dashboard {
  padding: 1rem !important;
  .b-tabs .tab-content {
    padding: 0.1rem 0 0 0 !important;
  }
}
</style>
