import {
  required,
  confirmed,
  email,
  length,
  numeric
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import axios from "axios";

extend("required", {
  ...required,
  message: "Feltet er påkrevd"
});

extend("confirmed", {
  ...confirmed,
  message: "Passordene matcher ikke"
});

extend("email", {
  ...email,
  message: "Må være en gyldig e-postadresse"
});

extend("numeric", {
  ...numeric,
  message: "Må kun bestå av tall"
});

extend("length", {
  ...length,
  message: "Må bestå av {length} tegn"
});

extend("phonenumber_valid", {
  validate(value, args) {
    if (args.isValid === "true") {
      return true;
    } else {
      return false;
    }
  },
  message: "Telefonnummeret er ikke gyldig!",
  params: ["isValid"]
});

extend("email_exists", {
  async validate(value, args) {
    if (value.toLowerCase() == args.originalEmail.toLowerCase()) {
      return true;
    }

    return await axios
      .get(`${window.apiURL}/admin/users/lookupEmail/${value}`)
      .then(response => {
        if (response.data && response.data.id) {
          return false;
        } else {
          return true;
        }
      });
  },
  message: "E-posten eksisterer allerede i systemet!",
  params: ["originalEmail"]
});

extend("phonenumber_exists", {
  async validate(value, args) {
    if (args.phoneData == args.originalPhoneNumber) {
      return true;
    }

    return await axios
      .get(`${window.apiURL}/admin/users/lookupPhoneNumber/${args.phoneData}`)
      .then(response => {
        if (response.data && response.data.id) {
          return false;
        } else {
          return true;
        }
      });
  },
  message: "Telefonnummeret er allerede registrert i systemet!",
  params: ["phoneData", "originalPhoneNumber"]
});

extend("orgnr_exists", {
  validate(value, args) {
    if (args.id !== "null") {
      return true;
    }
    if (value.length == 9) {
      return axios
        .get(
          `${window.apiURL}/admin/${args.companyApiEndPoint}/lookup/${value}`
        )
        .then(response => {
          if (response.data && response.data.organizationNumber) {
            return false;
          } else {
            return true;
          }
        });
    }
  },
  message: "Organisasjonen er allerede registrert!",
  params: ["companyApiEndPoint", "id"]
});
