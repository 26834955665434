<template>
  <div>
    <div class="bg-white mt-4 rounded-lg chat-shadow">
      <article class="media">
        <div class="media-content">
          <div class="is-fullwidth">
            <b-field>
              <b-checkbox-button
                v-if="!isUserAgent"
                size="is-small"
                v-model="internalOverride"
              >
                <i class="is-size-7"
                  >Melding lagres
                  <strong>{{ internalOverride ? "internt" : "" }}</strong>
                  i
                  <strong>{{ $t(`items["${module}"]`) }} </strong></i
                >
              </b-checkbox-button>
            </b-field>
            <b-field>
              <b-field expanded>
                <b-input
                  ref="comment"
                  expanded
                  type="textarea"
                  v-model="replyMessage"
                  autofocus=""
                  @keydown.enter.native="metaCtrlEnter"
                ></b-input>
              </b-field>
            </b-field>

            <b-field>
              <b-button
                @click="sendMessage"
                :loading="isLoading"
                type="is-primary"
                class="px-5 py-2 is-pulled-right"
              >
                {{ btnTitle }}
              </b-button>
            </b-field>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      internalOverride: true,
      replyMessage: ""
    };
  },
  props: {
    message: String,
    module: {
      default: ""
    },
    btnTitle: {
      default: null
    },
    isLoading: Boolean,
    id: {
      default: null
    }
  },
  computed: {
    ...mapGetters(["isUserAgent"])
  },
  mounted() {
    this.replyMessage = this.message;
    //this.$refs.comment.$el.children[0].focus();
  },

  methods: {
    sendMessage() {
      let module = this.module;

      if (this.internalOverride) {
        module = "general";
      }

      this.$emit(
        "sendMessage",
        this.replyMessage,
        this.id,
        module,
        this.internalOverride
      );
      this.replyMessage = null;
    },
    metaCtrlEnter(event) {
      if (event.metaKey || event.ctrlKey) {
        this.sendMessage();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/chat";
</style>
