<template>
  <div>
    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" />
    <article class="media" v-for="item in items" :key="item.id">
      <div :class="`media-content`">
        <div class="content">
          <router-link
            class="has-text-black"
            :to="{ name: 'item.edit', params: { id: item.itemId } }"
          >
            <strong>{{ item.title }}</strong>
          </router-link>

          <b-tooltip
            append-to-body
            :label="
              `${$t(`items.${item.module}`)}, ${daysTo(
                item.reminderDatetime
              )} dager igjen`
            "
          >
            <span
              class="tag is-small"
              :class="expirationColor(item.reminderDatetime)"
            >
              <b-icon :icon="getModuleIcon(item.module)" size="is-small" />
              <small>{{
                getFormattedDateTime(item.reminderDatetime, "DD.MM.YYYY")
              }}</small>
            </span>
          </b-tooltip>
          <br />

          {{ item.message }}
        </div>
        <nav class="level is-mobile">
          <div class="level-left">
            <div class="buttons">
              <router-link
                class=""
                :to="{ name: 'item.edit', params: { id: item.itemId } }"
              >
                <b-button
                  outlined
                  inverted
                  v-if="itemId != item.itemId"
                  type="is-black is-light mr-1"
                  icon-left="open-in-new"
                  size="is-small"
                >
                  se objekt
                </b-button>
              </router-link>

              <b-button
                outlined
                inverted
                @click="markRead(item.id)"
                type="is-black is-light"
                icon-left="check"
                size="is-small"
              >
                lest
              </b-button>
            </div>
          </div>
        </nav>
      </div>
    </article>
    <div
      v-if="items && items.length <= 0"
      class="notification is-warning is-light has-text-centered"
    >
      Du har ingen varsler!
    </div>
  </div>
</template>

<script>
import AsideUpdatesItem from "@/components/AsideUpdatesItem";
import helpers from "../helpers";
import moment from "moment";

export default {
  name: "AsideUpdates",
  components: { AsideUpdatesItem },
  mixins: [helpers],
  data() {
    return {
      isLoading: false,
      items: [],
      itemId: null
    };
  },
  mounted() {
    this.getData();

    // check if route is item and what ID
    const route = this.$route;
    if (route.name === "item.edit") {
      this.itemId = route.params.id;
    }
  },
  methods: {
    daysTo(date) {
      const days = moment(date).diff(moment(), "days");

      return days > 0 ? days : 0;
    },

    expirationColor(date) {
      // get number of days from now till expiration date
      const daysTo = this.daysTo(date);

      if (daysTo <= 0) {
        return "is-danger";
      } else if (daysTo > 0 && daysTo <= 2) {
        return "is-danger is-light";
      } else if (daysTo > 2 && daysTo <= 4) {
        return "is-warning";
      } else if (daysTo > 4 && daysTo <= 31) {
        return "is-warning is-light";
      } else if (daysTo > 31) {
        return "is-success is-light";
      }
    },

    markRead(id) {
      this.isLoading = true;
      // mark reminder as read
      this.$axios
        .put(`${window.apiURL}/admin/reminders/${id}`)
        .then(response => {
          // remove reminder from list
          this.items = this.items.filter(item => item.id !== id);

          this.$buefy.toast.open({
            message: response.data.message,
            type: "is-success"
          });
        })
        .catch(e => {
          this.$buefy.toast.open({
            message: e.response?.data?.message
              ? e.response.data.message
              : e.message,
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.$emit("data-updated");
          this.isLoading = false;
        });
    },

    async getData() {
      this.isLoading = true;

      // get reminders
      await this.$axios
        .get(`${window.apiURL}/admin/reminders`)
        .then(response => {
          this.items = response.data;
        })
        .catch(e => {
          this.isLoading = false;

          this.$buefy.toast.open({
            message: e.response?.data?.message
              ? e.response.data.message
              : e.message,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.$emit("data-updated");
          this.isLoading = false;
        });
    }
  },
  // watch for route item.edit and id
  watch: {
    $route(to, from) {
      if (to.name === "item.edit") {
        this.itemId = to.params.id;
      }
    }
  }
};
</script>
